import { yupResolver } from '@hookform/resolvers/yup';
import { isSameDay } from 'date-fns';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import AddToBasketConfirmationDialog from '@/components/AddToBasketConfirmationDialog/AddToBasketConfirmationDialog';
import Snackbar from '@/components/ui/Snackbar/Snackbar';
import { HOURS } from '@/helpers/constants';
import { getHoursResetValue } from '@/helpers/directRequest';
import { useBasketContext } from '@/hooks/useBasketContext';
import { useBasketPreviewContext } from '@/hooks/useBasketPreviewContext';
import { useDirectRequestFormContext } from '@/hooks/useDirectRequestFormContext';
import useFormatMessage from '@/hooks/useFormatMessage';

import RowVariant from './lib/RowVariant';
import StackVariant from './lib/StackVariant';

const validationSchema = Yup.object({
  rentalObject: Yup.object({
    name: Yup.string()
      .trim()
      .required(),
    productReferenceId: Yup.number().nullable(),
    salesforceId: Yup.string().nullable(),
    productGroupName: Yup.string().nullable(),
    productGroupId: Yup.number().nullable(),
    automatedOffer: Yup.bool(),
    sendAutomatedOffer: Yup.bool(),
    minLeadTime: Yup.number().nullable(),
    rentalPriceBasis: Yup.string().nullable(),
    rentalPrice: Yup.number().nullable(),
    invoiceBasis: Yup.string().nullable(),
  }),
  count: Yup.number().required(),
  dateFrom: Yup.date().required(),
  dateTo: Yup.date().required(),
  rentalHours: Yup.number().nullable(),
});

const DirectRequestForm = React.forwardRef(
  (
    {
      className,
      onSubmit = () => {},
      onRedirectWithItemsInBasket = () => {},
      closeDialog = null,
      showInfoText = true,
      showAddNextItemButton = true,
      submitButtonLabel = null,
      variant = 'stack',
    },
    ref
  ) => {
    const router = useRouter();
    const {
      requestData,
      updateRequestData,
      resetRentalObjectData,
    } = useDirectRequestFormContext();
    const {
      addRequestToBasket,
      requestsInBasketCount,
      machineAlreadyInBasket,
    } = useBasketContext();
    const { openBasketPreview } = useBasketPreviewContext();
    const formatMessage = useFormatMessage();

    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [duplicatedMachineData, setDuplicatedMachineData] = useState(null);

    const {
      control,
      handleSubmit,
      setValue,
      watch,
      setFocus,
      formState: { isSubmitting },
      clearErrors,
    } = useForm({
      defaultValues: requestData,
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    });

    const dateFromWatched = watch('dateFrom');
    const dateToWatched = watch('dateTo');
    const rentalObjectName = watch('rentalObject.name');
    const rentalObjectInvoiceBasis = watch('rentalObject.invoiceBasis');
    const rentalHoursWatched = watch('rentalHours');

    const showHourCounter =
      HOURS.includes(rentalObjectInvoiceBasis) &&
      isSameDay(dateFromWatched, dateToWatched);

    const insideDialog = !!closeDialog;

    const onSnackbarClose = (e, reason) => {
      if (reason !== 'clickaway') {
        setShowSuccessSnackbar(false);
      }
    };

    const handleOnChange = ({ data, inputName, value, onChange }) => {
      let updatedData = {};

      if (data) {
        updatedData = data;
      } else if (inputName) {
        updatedData = { [inputName]: value };
      }

      updateRequestData(updatedData);
      onChange(value);
    };

    const handleHoursChangeFromRentalObject = value => {
      if (HOURS.includes(value?.invoiceBasis)) {
        setValue(
          'rentalHours',
          getHoursResetValue(dateFromWatched, dateToWatched)
        );
      } else if (rentalHoursWatched !== null) {
        setValue('rentalHours', null);
      }
    };

    const handleDateFromChange = (value, onChange) => {
      handleOnChange({
        data: { dateFrom: value, dateTo: null },
        value,
        onChange,
      });
      setValue('dateTo', null, { shouldDirty: true });
      setFocus('dateTo');
    };

    const handleDateToChange = newDateTo => {
      if (isSameDay(newDateTo, dateToWatched)) return;
      if (dateFromWatched && HOURS.includes(rentalObjectInvoiceBasis)) {
        setValue('rentalHours', getHoursResetValue(dateFromWatched, newDateTo));
      }
    };

    const handleDuplicatedMachine = (callback, withRedirect = false) => (
      values,
      event
    ) => {
      if (machineAlreadyInBasket(values.rentalObject)) {
        setDuplicatedMachineData({
          machineName: values.rentalObject.name,
          values,
          withRedirect,
        });
      } else {
        callback(values, event);
      }
    };

    const handleFormSubmit = async values => {
      addRequestToBasket(values);
      resetRentalObjectData();
      onSubmit();
      if (router.pathname !== '/basket') {
        await router.push('/basket');
      } else {
        setDuplicatedMachineData(null);
      }
    };

    const handleOnSubmitError = async () => {
      if (requestsInBasketCount > 0 && !rentalObjectName.trim()) {
        clearErrors();
        resetRentalObjectData();
        onRedirectWithItemsInBasket();
        if (router.pathname !== '/basket') {
          await router.push('/basket');
        }
      }
    };

    const handleAddMachine = (values, event) => {
      addRequestToBasket(values);
      resetRentalObjectData();
      setValue('rentalObject', {
        name: '',
        productReferenceId: null,
        salesforceId: null,
        productGroupName: null,
        productGroupId: null,
        automatedOffer: false,
        sendAutomatedOffer: false,
        minLeadTime: null,
        rentalPriceBasis: null,
        rentalPrice: null,
        invoiceBasis: null,
      });
      setValue('count', 1);
      setFocus('rentalObject');
      setValue('rentalHours', null);

      setShowSuccessSnackbar(true);
      if (!insideDialog) {
        openBasketPreview({ closeTimeout: 10000, event });
      }
      setDuplicatedMachineData(null);
    };

    const handleAddDuplicatedMachine = () => {
      if (duplicatedMachineData.withRedirect) {
        handleAddMachine(duplicatedMachineData.values);
      } else {
        handleFormSubmit(duplicatedMachineData.values);
      }
    };

    const handleDontAddDuplicatedMachine = async () => {
      resetRentalObjectData();
      if (router.pathname !== '/basket') {
        closeDialog?.();
        await router.push('/basket');
      } else {
        setDuplicatedMachineData(null);
      }
    };

    const Form = variant === 'stack' ? StackVariant : RowVariant;

    const formProps = {
      className,
      insideDialog,
      control,
      onSubmit: handleSubmit(
        handleDuplicatedMachine(handleFormSubmit),
        handleOnSubmitError
      ),
      handleHoursChangeFromRentalObject,
      handleOnChange,
      handleDateFromChange,
      handleDateToChange,
      handleAddNextItem: handleSubmit(
        handleDuplicatedMachine(handleAddMachine, true)
      ),
      dateFromWatched,
      isSubmitting,
      submitButtonLabel,
      showInfoText,
      showAddNextItemButton,
      showHourCounter,
    };

    return (
      <>
        <Form ref={ref} {...formProps} />
        <Snackbar
          severity="success"
          onClose={onSnackbarClose}
          open={showSuccessSnackbar}
          message={formatMessage('dr_form_snackbar')}
          SnackbarProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        />
        <AddToBasketConfirmationDialog
          open={!!duplicatedMachineData}
          onAddAgain={handleAddDuplicatedMachine}
          onDontAddAgain={handleDontAddDuplicatedMachine}
          machineName={duplicatedMachineData?.machineName}
        />
      </>
    );
  }
);

DirectRequestForm.displayName = 'DirectRequestForm';

export const DirectRequestFormPropTypes = {
  className: PropTypes.string,
  /** Function triggered after DirectRequestForm's onSubmit handler. */
  onSubmit: PropTypes.func,
  onRedirectWithItemsInBasket: PropTypes.func,
  showInfoText: PropTypes.bool,
  showAddNextItemButton: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  closeDialog: PropTypes.func,
  variant: PropTypes.oneOf(['stack', 'row']),
};

DirectRequestForm.propTypes = DirectRequestFormPropTypes;

export default DirectRequestForm;
