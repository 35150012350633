import { Typography } from '@mui/material';
import { isSunday } from 'date-fns';
import React from 'react';
import { Controller } from 'react-hook-form';

import ProductAutocompleteField from '@/components/ProductAutocompleteField/ProductAutocompleteField';
import HourCounter from '@/components/ui/HourCounter/HourCounter';
import InputCalendarLegacy from '@/components/ui/InputCalendarLegacy/InputCalendarLegacy';
import InputCounter from '@/components/ui/InputCounter/InputCounter';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import { directRequestFormVariantPropType } from '@/constants/propTypes';
import useFormatMessage from '@/hooks/useFormatMessage';

import {
  ActionButton,
  ButtonsWrapper,
  CounterWrapper,
  DateRangePickerWrapper,
  Heading,
  MainWrapper,
  Wrapper,
} from './StackVariant.style';

const StackVariant = React.forwardRef(
  (
    {
      className,
      onSubmit,
      control,
      insideDialog,
      handleHoursChangeFromRentalObject,
      handleOnChange,
      handleDateFromChange,
      handleDateToChange,
      handleAddNextItem,
      dateFromWatched,
      showHourCounter,
      isSubmitting,
      submitButtonLabel,
      showAddNextItemButton,
      showInfoText,
    },
    ref
  ) => {
    const formatMessage = useFormatMessage();

    const FormWrapper = insideDialog ? React.Fragment : MainWrapper;

    return (
      <Wrapper className={className} ref={ref} data-cy="form: direct request">
        <FormWrapper>
          <Heading variant="h4" component="div">
            {formatMessage('dr_form_header')}
          </Heading>
          <form noValidate onSubmit={onSubmit}>
            <Controller
              name="rentalObject"
              control={control}
              render={({
                field: { ref, value, onChange, name, onBlur },
                fieldState: { error },
              }) => (
                <ProductAutocompleteField
                  required
                  error={!!error}
                  inputRef={ref}
                  helperText={
                    error ? formatMessage('dr_form_machineError') : ' '
                  }
                  value={value?.name}
                  onChange={value => {
                    handleHoursChangeFromRentalObject(value);
                    handleOnChange({
                      onChange,
                      inputName: name,
                      value,
                    });
                  }}
                  onBlur={onBlur}
                  name={name}
                  insideDialog={insideDialog}
                  only="rentable"
                />
              )}
            />
            <Controller
              name="count"
              control={control}
              render={({
                field: { ref, value, onChange, onBlur, name },
                fieldState: { error },
              }) => {
                return (
                  <CounterWrapper>
                    <InputCounter
                      inputRef={ref}
                      onChange={value => {
                        handleOnChange({ onChange, inputName: name, value });
                      }}
                      onBlur={onBlur}
                      value={value}
                      min={1}
                      error={error}
                      name={name}
                      label={formatMessage('dr_form_quantity')}
                    />
                  </CounterWrapper>
                );
              }}
            />
            <DateRangePickerWrapper>
              <Controller
                name="dateFrom"
                control={control}
                render={({
                  field: { ref, value, onChange, onBlur, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputCalendarLegacy
                      value={value}
                      id="directRequestDateFrom"
                      label={formatMessage('dr_form_start_date')}
                      onChange={e => handleDateFromChange(e, onChange)}
                      onBlur={onBlur}
                      disabledTo={new Date()}
                      isDateDisabled={isSunday}
                      tabIndex={0}
                      inputRef={ref}
                      name={name}
                      error={!!error}
                      helperText={
                        error
                          ? formatMessage('dr_form_mandatoryInputsHelper', {
                              inputName: formatMessage('dr_form_start_date'),
                            })
                          : ' '
                      }
                      required
                    />
                  );
                }}
              />
              <Controller
                name="dateTo"
                control={control}
                render={({
                  field: { ref, value, onChange, onBlur, name },
                  fieldState: { error },
                }) => {
                  return (
                    <InputCalendarLegacy
                      value={value}
                      id="directRequestDateTo"
                      label={formatMessage('dr_form_end_date')}
                      onChange={value => {
                        handleOnChange({ onChange, inputName: name, value });
                        handleDateToChange(value);
                      }}
                      onBlur={onBlur}
                      disabledTo={dateFromWatched || new Date()}
                      isDateDisabled={isSunday}
                      tabIndex={0}
                      inputRef={ref}
                      name={name}
                      error={!!error}
                      helperText={
                        error
                          ? formatMessage('dr_form_mandatoryInputsHelper', {
                              inputName: formatMessage('dr_form_end_date'),
                            })
                          : ' '
                      }
                      required
                      presented={
                        dateFromWatched
                          ? new Date(
                              new Date(dateFromWatched).setDate(
                                new Date(dateFromWatched).getDate() + 1
                              )
                            )
                          : new Date()
                      }
                    />
                  );
                }}
              />
            </DateRangePickerWrapper>
            {showHourCounter && (
              <Controller
                name="rentalHours"
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => {
                  return (
                    <HourCounter
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                      onHourChange={updatedValue => {
                        handleOnChange({
                          onChange,
                          inputName: name,
                          value: updatedValue,
                        });
                      }}
                    />
                  );
                }}
              />
            )}
            <ButtonsWrapper>
              {showAddNextItemButton && (
                <ActionButton
                  size="large"
                  color="primaryLight"
                  fullWidth
                  data-cy="button: addNextItem"
                  onClick={handleAddNextItem}
                  tabIndex={-1}
                  disabled={isSubmitting}>
                  {formatMessage('dr_form_add_machine')}
                </ActionButton>
              )}
              <ActionButton
                size="large"
                data-cy="button: addToBasket"
                type="submit"
                fullWidth
                tabIndex={0}
                disabled={isSubmitting}
                startIcon={<SvgIcon name="shoppingCart" sx={{ mb: '3px' }} />}>
                {submitButtonLabel ?? formatMessage('dr_form_cta')}
              </ActionButton>
            </ButtonsWrapper>
            {showInfoText && (
              <Typography
                variant="caption"
                sx={{ color: theme => theme.palette.grayscale[600] }}>
                {formatMessage('dr_form_mandatory')}
              </Typography>
            )}
          </form>
        </FormWrapper>
      </Wrapper>
    );
  }
);

StackVariant.displayName = 'StackVariant';

StackVariant.propTypes = directRequestFormVariantPropType;

export default StackVariant;
