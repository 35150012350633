import { formHelperTextClasses, inputBaseClasses, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import Button from '@/components/ui/Button/Button';
import Paper from '@/components/ui/Paper/Paper';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 900,
  [`.${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.grayscale[100],
  },
  [`.${formHelperTextClasses.root}`]: {
    marginBottom: theme.spacing(0.5),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1130,
  },
}));

export const MainWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3.25, 2, 3.25, 2),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3.25, 2, 1, 2),
  },
  borderRadius: 15,
}));

export const InnerWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(1.5),
  },
}));

export const RentalObjectWrapper = styled(Stack)(({ theme }) => ({
  gap: 0,
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(1.5),
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  maxWidth: 'initial',
  height: 56,

  [theme.breakpoints.up('lg')]: {
    maxWidth: 200,
  },
}));
