import React, { useEffect, useRef, useState } from 'react';

import DirectRequestForm from '@/components/DirectRequestForm/DirectRequestForm';
import FloatingCTA from '@/components/FloatingCTA/FloatingCTA';
import RatingWidget from '@/components/RatingWidget/RatingWidget';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import {
  Background,
  FormWrapper,
  Heading,
  PageMargins,
  Wrapper,
} from './Hero.style';

export default function Hero() {
  const [floatingCTA, setFloatingCTA] = useState(false);
  const directRequestFormRef = useRef();
  const formatMessage = useFormatMessage();
  const mobileMediaQuery = useMobileMediaQuery();

  const handleCTAClick = () => {
    const additionalSpaceAboveForm = 150;
    window.scrollBy({
      top:
        directRequestFormRef.current.getBoundingClientRect().top -
        additionalSpaceAboveForm,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (directRequestFormRef.current) {
        setFloatingCTA(
          directRequestFormRef.current.getBoundingClientRect().bottom <
            (mobileMediaQuery ? 0 : 67)
        );
      }
    };
    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [mobileMediaQuery]);

  return (
    <>
      <Wrapper mt={4}>
        <PageMargins>
          <RatingWidget />
        </PageMargins>
        <Background>
          <PageMargins>
            <Heading
              align="center"
              component="h1"
              variant="h1Redesign"
              color="primary.dark">
              {formatMessage('hero_headline')}
            </Heading>
            <FormWrapper>
              <DirectRequestForm ref={directRequestFormRef} variant="row" />
            </FormWrapper>
          </PageMargins>
        </Background>
      </Wrapper>
      {floatingCTA && <FloatingCTA onClick={handleCTAClick} />}
    </>
  );
}
