import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TrustElements = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2.5),
  minHeight: 35,
  borderRadius: 15,
}));

export const LogoWrapper = styled('div')(() => ({
  a: {
    display: 'flex',
  },
}));
