import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import PageMarginsBase from '@/components/ui/PageMargins/PageMargins';
import PageTopMarginWrapper from '@/components/ui/PageTopMarginWrapper/PageTopMarginWrapper';
import theme from '@/styles/theme';

export const Wrapper = styled(PageTopMarginWrapper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
}));

export const FormWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(12),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(24),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(28),
  },
}));
export const Background = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(4.5),

  backgroundImage: 'url("/images/hero-sm.webp")',
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: `-32px, top`,
  backgroundPositionX: 'center',
  backgroundSize: 'contian',

  [theme.breakpoints.up('sm')]: {
    backgroundImage: 'url("/images/hero-md.webp")',
    backgroundPositionY: 'top',
  },
  [theme.breakpoints.up('md')]: {
    backgroundImage: 'url("/images/hero-lg.webp")',
  },
  [theme.breakpoints.up('lg')]: {
    backgroundImage: 'url("/images/hero-xl.webp")',
    backgroundPositionY: '-128px, top',
  },
}));

export const PageMargins = styled(PageMarginsBase)(() => ({
  width: '100%',
}));

export const Heading = styled(Typography)(() => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(60),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(32),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(1.5),
  },
}));
