import { Paper } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';

const appear = keyframes`
   from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
`;

export const Wrapper = styled('div', {
  shouldForwardProp: prop => !['fullWidth'].includes(prop),
})(({ theme, fullWidth }) => ({
  position: 'relative',
  minWidth: 145,
  flexGrow: 1,
  [theme.breakpoints.up('md')]: {
    width: fullWidth ? 'initial' : 145,
  },
}));

export const CalendarWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  overflow: 'hidden',
  animationName: appear,
  animationDuration: '0.3s',
  zIndex: theme.zIndex.popper,
  borderRadius: theme.shape.borderRadiusLarge,
  transform: `translateY(-${theme.spacing(1)})`,
  '& .MuiYearCalendar-root': {
    width: '100%',
  },
}));
