import Input from '@/components/ui/Input/Input';

function NumberInput({
  id,
  name,
  value,
  label,
  inputRef,
  onChange,
  onBlur,
  error,
  min = 1,
  max = 99,
  helperText = ' ',
  'data-cy': dataCy,
  ...props
}) {
  const getNumberInputValue = (numberToBe, min, max) => {
    if (numberToBe < min) {
      return min;
    } else if (numberToBe > max) {
      return max;
    }
    return numberToBe;
  };

  const handleChange = e => {
    const { valueAsNumber } = e.target;
    if (Number.isNaN(valueAsNumber)) return;
    const num = getNumberInputValue(valueAsNumber, min, max);
    onChange(num);
  };

  return (
    <Input
      id={id}
      name={name}
      label={label}
      onChange={handleChange}
      onClick={() => inputRef.current.select()}
      onBlur={onBlur}
      error={!!error}
      type="number"
      inputProps={{
        inputMode: 'numeric',
        min,
        max,
        step: 1,
        'data-cy': dataCy || 'input: counter',
      }}
      value={value}
      variant="outlined"
      size="medium"
      fullWidth
      helperText={helperText}
      inputRef={e => {
        if (inputRef) {
          if (typeof inputRef === 'function') {
            inputRef(e);
          } else {
            inputRef.current = e;
          }
        }
        inputRef.current = e;
      }}
      {...props}
    />
  );
}

export default NumberInput;
