import { Star as MuiStar } from '@mui/icons-material';
import { Rating, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { AVERAGE_RATING } from '@/helpers/constants';
import useFormatMessage from '@/hooks/useFormatMessage';

import googleLogo from './images/google-logo.png';
import trustamiLogo from './images/trustami-logo.png';
import trustpilotLogo from './images/trustpilot-logo.png';
import { LogoWrapper, TrustElements } from './RatingWidget.style';

function RatingWidget() {
  const formatMessage = useFormatMessage();

  const ratingLogos = [
    {
      logo: googleLogo,
      href: 'https://search.app.goo.gl/UKhxci5',
      altText: 'Google Logo',
    },
    {
      logo: trustpilotLogo,
      href: 'https://de.trustpilot.com/review/klickrent.de',
      altText: 'Trustpilot Logo',
    },
    {
      logo: trustamiLogo,
      href: 'https://www.trustami.com/erfahrung/klickrent-gmbh-bewertung',
      altText: 'Trustami Logo',
    },
  ];

  return (
    <Stack alignItems="center">
      <TrustElements elevation={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          columnGap={2}
          rowGap={1}
          flexWrap="wrap">
          <Rating
            name="avg-trust-rating"
            value={5}
            readOnly
            precision={0.1}
            emptyIcon={<MuiStar style={{ opacity: 1 }} fontSize="inherit" />}
          />
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontSize: 14 }}>
            <strong>{AVERAGE_RATING} </strong>
            {formatMessage('rating_widget_text')}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}>
            {ratingLogos.map(logo => (
              <LogoWrapper key={logo.altText}>
                <Link
                  href={logo.href}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Image
                    src={logo.logo}
                    alt={logo.altText}
                    style={{ objectFit: 'contain' }}
                    height={18}
                  />
                </Link>
              </LogoWrapper>
            ))}
          </Stack>
        </Stack>
      </TrustElements>
    </Stack>
  );
}

export default RatingWidget;
